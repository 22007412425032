<template>
    <article class="maintenance">
        <h1>We&rsquo;ll be back soon!</h1>
        <br>
        <div>
            <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you
                can always contact out support, otherwise we&rsquo;ll be back online shortly!</p>
            <p>&mdash; Team eAcademyNepal</p>
        </div>
    </article>
</template>

<script>
    export default {
        name: "UnderMaintenance"
    }
</script>

<style lang="scss">
    article.maintenance {
        padding: 150px;
        font: 20px Helvetica, sans-serif;
        color: #333;
        line-height: 1.4;

        display: block;
        text-align: left;
        width: 1150px;
        margin: 0 auto;
        h1 {
            font-size: 50px;
        }
        a {
            color: #dc8100;
            text-decoration: none;
        }
        a:hover {
            color: #333;
            text-decoration: none;
        }
    }

</style>