import NavFactory from "../../../library/NavFactory"
let routes = [{
    name: 'Template Manager',
    icon: 'layers',
    header: true,
    service:true,
    permission: true,
    callBackFunc: NavFactory.isAdministrator,
    children: [
        {
            name: 'Certificate',
            path: '/newcertificate-template',
            icon: 'insert_drive_file',
            // permission: 'template-create',
            permission: true,
            // callBackFunc: NavFactory.isAdministrator
            
        },
        {
            name: 'Entrance Card',
            path: '/entrancecard-template',
            icon: 'insert_drive_file',
            // permission: 'template-create'
            permission: true
        },
        {
            name: 'ID Card',
            path: '/idcard-template',
            icon: 'insert_drive_file',
            permission: true
        },    
    ]
}];

export default routes;