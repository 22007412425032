<template>
  <div @click="showWhatsNew">
    <div class="pb-2 whats_new_update">
      <icon-header-sub-header
        :sub="totalUpdates() + '+ new updates (' + latestVersionNumber() + ')'"
      ></icon-header-sub-header>
    </div>
    <v-dialog v-model="show" persistent max-width="700">
      <v-card>
        <v-card-title class="title pa-3 warning white--text">
          <div style="margin-right:auto;">
            <v-icon class="mr-2 white--text">info</v-icon>
            What's new on version {{ latestVersionNumber() }} ({{wnAll.date || '-'}})
          </div>
          <div
            id="close_whats_new_modal"
            @click="closeUpdateModal"
            style="margin-left:auto;float:right;cursor:pointer;"
          >
            <v-icon color="white">close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="ma-0">
          <v-timeline align-top dense>
            <v-timeline-item
              v-for="(item, key) in Object.keys(latest_list)"
              :color="serviceColor(item)"
              small
              :key="key"
            >
              <v-row class="ml-2 pt-3">
                <v-col class="pt-0" cols="3">
                  <strong>{{ item.toUpperCase() }}</strong>
                </v-col>
                <v-col class="pt-0">
                  <div
                    class="caption"
                    v-for="(update, key) in latest_list[item]"
                    :key="100 + key"
                  >
                    <span v-if="typeof update === 'string'"
                      >{{ ++key }}) {{ update }}
                    </span>
                    <span
                      v-else-if="typeof update === 'object'"
                      style="padding-left:20px;display:block;color:red;"
                    >
                      <div style="text-decoration:underline;">
                        {{ update.version }}
                      </div>
                      <div v-for="(val, x) in update.updates" :key="key + x">
                        {{ x + 1 }}) {{ val }}
                      </div>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>

          <hr />
          <p
            style="color:#999;font-size:12px;padding:15px 0 0 15px;line-height:1.4;margin-bottom:0;"
          >
            If any bugs/issues found please report it to our support at school's
            telegram group or give us a call 9847060846.
          </p>
          <p style="font-size:12px;padding-left:15px;margin-top:10px;">
            - eAcademyNepal
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import WhatsNew from "@/library/WhatsNew";
export default {
  data() {
    return {
      show: false,
      latest_list: [],
      wnAll:WhatsNew.getAll(),
    };
  },
  mounted() {
    if (!this.$storage.get("_lates_version")) {
      this.show = true;
    } else if (
      this.$storage.get("_lates_version") < this.latestVersionNumber()
    ) {
      this.show = true;
    }

    this.getLatests();
  },
  methods: {
    getLatests() {
      this.latest_list = WhatsNew.getLatest();
    },
    latestVersionNumber: WhatsNew.latestVersionNumber,
    totalUpdates: WhatsNew.totalUpdates,
    showWhatsNew() {
      this.show = true;
    },
    serviceColor(service) {
      return WhatsNew.getColor(service);
    },
    closeUpdateModal() {
      this.$storage.set("_lates_version", this.latestVersionNumber());
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
.v-timeline-item__divider {
  min-width: 25px !important;
}
.v-timeline--dense .v-timeline-item__body {
  max-width: calc(100% - 23px) !important;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before,
.v-application--is-rtl .v-timeline--reverse.v-timeline--dense:before {
  left: 10px !important;
}
.v-application .caption {
  font-size: 0.8rem !important;
  color: #666 !important;
  margin-bottom: 5px;
}
</style>
