import NavFactory from "../../../library/NavFactory"

let routes = [{
	name: 'eAcademy Connect',
	icon: 'wifi_tethering',
	path: 'https://manage.eacademyconnect.com/auth/login',
	permission: true,
	service:true
}];

export default routes;
