import NavFactory from "../../../library/NavFactory";
let routes = [
    {
        name: 'Live Class',
        icon: 'cast',
        header: true,
        permission:'live-class-read',
        service:'live-class',
        children: [
            {
                name: 'Activity',
                icon: 'cast',
                permission: 'live-class-read',
                children: [
                    {
                        name: 'Join/Host Class',
                        path: '/live-class',
                        icon: 'cast',
                        permission: 'live-class-read'
                    },
                   
                ]
            },

            {
                name: 'Reports',
                icon: 'payment',
                permission: 'live-class-read',
                children: [
                    {
                        name: 'Attendance Report',
                        path: '/zoom/attendance',
                        icon: 'subject',
                        permission: 'live-class-read'
                    }, 
                ]
            }]
    }
];

export default routes;