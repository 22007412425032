import cookie from 'js-cookie'
import NavFactory from "@/library/NavFactory";
import ls from '@/library/Storage';

let routes = [
	{
		name: 'Accounting',
		icon: 'payment',
		path: '/accounting',
		permission: true,
		service:'account',
		callBackFunc: NavFactory.isAccountant,
		callee: function () {
			let receiver = document.getElementById('recieve');
			let push = (receiver.contentWindow);
			let data = [];
			data['local_storage'] = {
				_currentFiscalYear: localStorage.getItem('_currentFiscalYear'),
				_permissions: localStorage.getItem('_permissions'),
				_token: localStorage.getItem('_token'),
				// batch: localStorage.getItem('batch'),
				_role: localStorage.getItem('_role'),
				_user: localStorage.getItem('_user'),
			};
			data['cookie'] = {
				_cl: cookie.get('_cl'),
				// _batch: cookie.get('_batch'),
				_company: ls.get('_company'),
			};

			let transferedUri = 'http://localhost:4000';

			let uri = window.location.host;
			uri = uri.replace(/^.{2,6}\./, '');
			if (uri === 'eacademy.sahakaryatech.com') {
				uri = uri.replace(/eacademy./, '')
			}

			if (uri === 'localhost:8080' || uri === 'localhost:8081') {
				transferedUri = 'http://localhost:4000'
			} else {
				transferedUri = 'https://account.' + uri;
			}

			push.postMessage(data, transferedUri);
			window.open(
				transferedUri,
				'_blank'
			);
		}
	}
];

export default routes;
