import NavFactory from "@/library/NavFactory";

let routes = [
  {
    name: "SMS",
    icon: "message",
    header: true,
    service:'sms',
    permission: "student-read",
    callBackFunc: NavFactory.isAdministrator,
        children: [
            {
                name: 'Setting',
                path: '',
                icon: '',
                permission: 'template-create|template-delete',

                children: [
                    {
                        name: 'Template',
                        path: '/templates',
                        icon: 'layers',
                        permission: 'template-create',

                    },
                ]
            },
            {
                name: 'Activity',
                path: '',
                icon: '',
                permission: 'send-group|send-bulk|send-single',
                children: [
                    {
                        name: 'Single SMS',
                        path: '/single-sms',
                        icon: 'layers',
                        permission: 'send-single',
                    },
                    {
                        name: 'Bulk SMS',
                        path: '/bulk-sms',
                        icon: 'layers',
                        permission: 'send-bulk',
                    },
                    {
                        name: 'Group SMS',
                        path: '/group-sms',
                        icon: 'layers',
                        permission: 'send-group',
                    },
                    // {
                    //     name: 'Advance SMS',
                    //     path: '/advance-sms',
                    //     icon: 'layers',
                    //     permission: 'send-group',
                    // }
                ]
            },
            {
                name: 'Report',
                path: '',
                icon: '',
                permission: 'send-group|send-bulk|send-single',
                children: [
                    {
                        name: 'SMS Reports',
                        path: '/sms-report',
                        icon: 'layers',
                        permission: 'template-create|template-delete',
                    }
                ]
            }
        ]
  }];

export default routes;
