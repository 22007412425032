// let routes = [{
//     name: 'Calendar',
//     icon: 'calendar_today',
//     path: '/calendar',
//     header: true,
//     permission: 'calendar-read',
//     service:'event'
// }];

let routes = [
    {
        name: 'Event',
        icon: 'calendar_today',
        header: true,
        service:'event',
        permission: 'event-read|event-create|event-update|event-delete',
        children:[
            {
                name: 'Calendar',
                path: '/calendar',
                icon: 'calendar_today',
                permission: 'event-read',
            },
            {
                name: 'Event List',
                path: '/event-list',
                icon: 'list',
                permission: 'event-read',
            },
            {
                name: 'Personal',
                path: '/personal',
                icon: 'list',
                permission: 'event-read',
            },
        ]
    }
];
export default routes;