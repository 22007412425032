import updates from '@/assets/json/updates.json'

export default class WhatsNew {

    static getAll() {
        return updates[Object.keys(updates)[0]];
    }

    static getLatest() {
        return (updates[Object.keys(updates)[0]]['updates']);
    }

    static totalUpdates() {
        let output = 0;
        const newUpdates = WhatsNew.getLatest();
        Object.keys(newUpdates).map((item) => {
            newUpdates[item].map(() => {
                output++;
            })
        });
        return output;
    }

    static latestVersionNumber() {
        return (Object.keys(updates)[0]);
    }

    static getColor(service) {
        return updates['colors'][service];
    }
}