let routes = [{
    name: 'Miscellaneous',
    icon: 'drag_indicator',
    header: true,
    service:'miscellaneous',
    permission: 'testimonial-read|album-read|notice-read|slider-read',
    children: [{
        name: 'Testimonial',
        path: '/testimonials',
        icon: 'perm_contact_calendar',
        permission: 'testimonial-read'
    }, {
        name: 'Album',
        path: '/album',
        icon: 'photo_album',
        permission: 'album-read'
    },{
        name: 'Photos',
        path: '/photo',
        icon: 'photo_album',
        permission: 'album-read'
    },{
        name: 'Notice',
        path: '/notice',
        icon: 'message',
        permission: 'notice-read'
    }, {
        name: 'Slider',
        path: '/slider',
        icon: 'slideshow',
        permission: 'slider-read'
    }, {
        name: 'Quote',
        path: '/quote',
        icon: 'message',
        permission: 'school-create'
    },{
        name: 'Service',
        path: '/service',
        icon: 'message',
        permission: 'notice-read'
    },{
        name: 'Media',
        path: '/media',
        icon: 'textsms',
        permission: 'notice-read'
    },{
        name: 'News',
        path: '/news',
        icon: 'message',
        permission: 'notice-read'
    },{
        name: 'Admission Request',
        path: '/admisson-request',
        icon: 'person_add',
        permission: 'notice-read'
    }]
}];

export default routes;