let routes = [
	{
	  name: 'Billing',
	  icon: 'monetization_on',
	  header: true,
	  service: 'billing',
	  permission:
		'fee-heads-read|fee-read|fee-offer-read|bill-generate-student-wise|bill-generate-class-wise',
	  children: [
			{
		  name: 'Setting',
		  path: '/studentwise-bill-generate',
		  icon: 'payment',
		  permission:
			'due-read|fee-heads-read|fee-read|fee-offer-read|discount-and-penalty-read',
		  children: [
					{
			  name: 'Add Previous Due',
			  path: '/previous-due',
			  icon: 'subtitles',
			  permission: 'due-read',
					},
					{
			  name: 'Fee Heads',
			  path: '/fee-heads',
			  icon: 'layers',
			  permission: 'fee-heads-read',
					},
					{
			  name: 'Fee Charge',
			  path: '/fee',
			  icon: 'line_style',
			  permission: 'fee-read',
					},
					{
			  name: 'Fee Offer/Discount',
			  path: '/fee-offer',
			  icon: 'receipt',
			  permission: 'fee-offer-read',
					},
					{
			  name: 'Penalty & Rebate',
			  path: '/discount-penalty',
			  icon: 'monetization_on',
			  permission: 'discount-and-penalty-read',
					},
					{
			  name: 'Billing Month',
			  path: '/billing-month',
			  icon: 'layers',
			  permission: true,
					},
					{
			  name: 'Eca Heads',
			  path: '/billing-eca-head',
			  icon: 'card_travel',
			  permission: true
					},
					{
			  name: 'Individual Head',
			  path: '/individual-eca-head',
			  icon: 'card_travel',
			  permission: true
  
					}
		  ],
			},
			{
		  name: 'Activity',
		  path: '/receipt-report',
		  icon: 'subject',
		  permission: 'receipt-read',
		  children: [
					{
			  name: 'Monthly Bill Generate',
			  path: '/monthly-bill-generate',
			  icon: 'message',
			  permission: 'bill-generate-class-wise',
					},
					{
			  name: 'Advance Journal',
			  path: '/advance-journal',
			  icon: 'featured_play_list',
			  permission: 'bill-generate-verify',
					},
					{
			  name: 'Posting (SOD/EOD)',
			  path: '/sod',
			  icon: 'list_alt',
			  permission: 'sod-read',
					},
					{
			  name: 'Fee Receipt',
			  path: '/fee-payment',
			  icon: 'message',
			  permission: 'receipt-read',
					},
					{
			  name: 'Quick Fee Payment',
			  path: '/quick-fee-payment',
			  icon: 'message',
			  permission: 'receipt-read',
					},
					{
			  name: 'General Receipt / Invoice',
			  path: '/fee-general-receipt',
			  icon: 'art_track',
			  permission: 'receipt-read',
					},
					{
			  name: 'Advance Payment',
			  path: '/advance-payment',
			  icon: 'list_alt',
			  permission: 'receipt-read',
					},
					{
			  name: 'Security Deposit',
			  path: '/security-deposit',
			  icon: 'list_alt',
			  permission: 'receipt-read',
					},
		  ],
			},
  
			{
		  name: 'Reports',
		  path: '/mapping',
		  icon: 'payment',
		  permission: 'receipt-read',
		  children: [
					{
			  name: 'Previous Due Report',
			  path: '/previous-due-report',
			  icon: 'subject',
			  permission: 'receipt-read',
					},
					{
			  name: 'Bill Generate Report',
			  path: '/bill-generate-report',
			  icon: 'list_alt',
			  permission: 'receipt-read',
					},
					{
			  name: 'Receipt Report',
			  path: '/receipt-report',
			  icon: 'subject',
			  permission: 'receipt-read',
					},
					{
			  name: 'Collection Report',
			  path: '/collection-due',
			  icon: 'business',
			  permission: 'billing-report-collection',
					},
					{
			  name: 'Title Wise Collection Report',
			  path: '/title-wise-eod-collection-report',
			  icon: 'view_array',
			  permission: 'billing-report-collection',
					},
					{
			  name: 'Title Wise Due Report',
			  path: '/title-wise-due-report',
			  icon: 'pending_actions',
			  permission: 'billing-report-collection',
					},
					{
			  name: 'Fee Offer Report',
			  path: '/fee-offer-report',
			  icon: 'view_array',
			  permission: 'billing-report-collection',
					},
					{
			  name: 'Statement',
			  path: '/statement',
			  icon: 'vertical_split',
			  permission: 'billing-report-statement',
					},
					{
			  name: 'Old Statement',
			  path: '/statement-old',
			  icon: 'vertical_split',
			  permission: 'billing-report-statement',
					},
					{
			  name: 'Student Bill Due Report',
			  path: '/student-due-report',
			  icon: 'business',
			  permission: 'billing-report-collection',
					},
					{
			  name: 'Summary Report',
			  path: '/summary-report',
			  icon: 'business',
			  permission: 'billing-report-collection',
					},
					{
			  name: 'Customized Fee Head Report',
			  path: '/customized-fee-heads-report',
			  icon: 'color_lens',
			  permission: 'billing-report-collection',
					},
					{
			  name: 'Security Deposit Report',
			  path: '/security-deposit-report',
			  icon: 'vertical_split',
			  permission: 'billing-report-collection',
					},
		  ],
			},
	  ],
	},
];
  
export default routes;
