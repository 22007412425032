import NavFactory from "@/library/NavFactory";
let routes = [{
	name: 'Academic',
	icon: 'school',
	header: true,
	service: 'academic',
	// permission: 'batch-read|grade-read|section-read|subject-read|subject-routine-read|attendance-read|assignment-read',
	permission: 'batch-read|grade-read|section-read|subject-read|subject-routine-read|attendance-read|assignment-read',
	children: [{
		name: 'Setting',
		path: '',
		icon: '',
		permission: 'grade-read|batch-read|subject-read|subject-routine-read|staff-create',
		children: [{
			name: 'Affiliation',
			path: '/affiliation',
			icon: 'insert_invitation',
			permission: 'batch-read'
		},
		{
			name: 'Level',
			path: '/level',
			icon: 'insert_invitation',
			permission: 'batch-read'
		},
		{
			name: 'Batches',
			path: '/batches',
			icon: 'insert_invitation',
			permission: 'batch-read'
		},
		{
			name: 'Grades',
			path: '/grades',
			icon: 'layers',
			permission: 'grade-read',
		},
		{
			name: 'Student Group',
			path: '/student-groups',
			icon: 'list_alt',
			permission: 'staff-create'
		},
		{
			name: 'Subjects',
			path: '/subjects',
			icon: 'book',
			permission: 'subject-read'
		},
		{
			name: 'Optional Group',
			path: '/opt-group',
			icon: 'ballot',
			permission: 'staff-create'
		},
		{
			name: 'Routines',
			path: '/routines',
			icon: 'list_alt',
			permission: 'subject-routine-read'
		},
		{
			name: 'Migrate Routines',
			path: '/migrate-routines',
			icon: 'swap_vert',
			permission: 'subject-create'
		},
		{
			name: 'Syllabus',
			path: '/syllabus',
			icon: 'book',
			permission: 'subject-read'
		},
		{
			name: 'Behavior Review and Remarks',
			path: '/remarks',
			icon: 'list_alt',
			permission: 'staff-create'
		},





			// {
			//     name: 'Faculty',
			//     path: '/faculty',
			//     icon: 'insert_invitation',
			//     permission: 'batch-read'
			// },

			/*{
                    name: 'Department',
                    path: '/department',
                    icon: 'list_alt',
                    permission: 'staff-create'
                }*/
            ]
        },
        {
            name: 'Activity',
            path: '',
            icon: '',
            permission: 'attendance-read|assignment-read',
            children: [{
                    name: 'Attendance',
                    path: '/attendance',
                    icon: 'how_to_reg',
                    permission: 'attendance-read',
                    callBackFunc: NavFactory.isClassTeacher
                },
                {
                    name: 'Assignments',
                    path: '/assignments',
                    icon: 'chrome_reader_mode',
                    permission: 'assignment-read'
                },
            ]
        },
        {
            name: 'Report',
            path: '',
            icon: '',
            permission: 'attendance-read',
            children: [{
                name: 'Attendance Report',
                path: '/attendance-report',
                icon: 'ballot',
                permission: 'attendance-view-report'
            }, {
                name: 'Assignment Report',
                path: '/assignment-report',
                permission: "staff-create",
                icon: 'ballot',
            }, {
                name: 'Behaviour Report',
                path: '/student-behaviour-report',
                permission: "staff-create",
                icon: 'ballot',
            }
            , {
                name: 'Behaviour Summary Report',
                path: '/student-behaviour-summary-report',
                permission: "staff-create",
                icon: 'ballot',
            }
            , {
                name: 'Student QR Logs',
                path: '/student-qr-logs',
                permission: 'attendance-read',
                callBackFunc: NavFactory.isAdministrator,
                icon: 'ballot',
            }
        ]
        },


	]
}];

export default routes;
