import NavFactory from "../../../library/NavFactory";

let routes = [
    {
        name: 'AIO',
        icon: 'search',
        path: '/aio',
        permission: 'batch-read',
        // callBackFunc: NavFactory.isAdministrator,
        callBackFunc: NavFactory.isDev,
        service:true
    }
];

export default routes;