import NavFactory from "@/library/NavFactory";

let routes = [
    {
        name: 'Library (BETA)',
        icon: 'school',
        header: true,
        service:'library',
        permission:'library-create|library-read|library-update|library-delete',
        children: [
            {
                name:'Setting',
                path:'',
                icon:'',
                permission:'library-read',
                children:[
                    {
                
                        name:'Author',
                        path:'/authors',
                        icon:'person',
                        permission:'library-read'
                    },
        
                    {
                        name:'Donor',
                        path:'/donors',
                        icon:'volunteer_activism',
                        permission:'library-read'
                    },
                    {
                        name:'Publisher',
                        path:'/publishers',
                        icon:'publish',
                        permission:'library-read'
                    },
                    {
                        name:'Rack',
                        path:'/racks',
                        icon:'all_inbox',
                        permission:'library-read'
                    },
                    {
                        name:'Vendor',
                        path:'/vendors',
                        icon:'local_library',
                        permission:'library-read'
                    },
                    {
                        name:'Category',
                        path:'/categories',
                        icon:'category',
                        permission:'library-read'
                    },
                    {
                        name:'Sector',
                        path:'/sectors',
                        icon:'insert_invitation',
                        permission:'library-read'
                    },
                    {
                        name:'Book',
                        path:'/books',
                        icon:'insert_invitation',
                        permission:'library-read'
                    }, 
                   
                ],
                
            },{
                name:'Activity',
                path:'',
                icon:'',
                permission:'library-read',
                children:[
                    {
                        name:'Book Issue',
                        path:'/books_issue',
                        icon:'insert_invitation',
                        permission:'library-read'
                    }
                ]

            },{
                name:'Report',
                path:'',
                icon:'',
                permission:'library-read',
                children:[
                    {
                        name:'Book Report',
                        path:'/book-report',
                        icon:'library_books',
                        permission:'library-read'
                    },
                    {
                        name:'Book Issue Report',
                        path:'/book-issue-report',
                        icon:'library_books',
                        permission:'library-read'
                    },
                    {
                        name:'Fine Collection Report',
                        path:'/fine-collection-report',
                        icon:'library_books',
                        permission:'library-read'
                    }
                ]
            }
        ]
    }];

export default routes;