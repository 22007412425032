<template>
  <div>
    <!-- <div v-if="$auth.getRole() !== 'guardian'  && has_expired"> -->
      <v-dialog
        v-model="paymentAlert.paymentAlertModel"
        persistent
        max-width="700"
        v-if="(has_expired && !['administrator','accountant'].includes($auth.getRole('role'))) || ['administrator','accountant'].includes($auth.getRole('role'))"
      >
        <v-card v-if="['administrator','accountant'].includes($auth.getRole('role'))" class="primary--border">
          <v-card-title
            :class="
              'title pa-3 white--text ' + paymentAlert.pendingInvoice.action_type
            "
          >
            <v-icon class="mr-2 white--text">warning</v-icon>
            Payment, Alert!!! (<span
              v-if="paymentAlert.pendingInvoice.action_type === 'error'"
              >CRITICAL</span
            ><span
              v-else-if="paymentAlert.pendingInvoice.action_type === 'success'"
              >NOTICE</span
            ><span v-else>WARNING</span>)
          </v-card-title>
          <v-card-text
            v-if="paymentAlert.pendingInvoice.payment_heads"
            class="ma-0"
          >
            <br />
            <alert-message
              :extra-small="true"
              :type="paymentAlert.pendingInvoice.action_type"
              title="Payment Alert."
              :message="paymentAlert.alertMessage"
            ></alert-message>
            <div class="plan_info">
              <div class="pi_content plan_info_title">Due Duration</div>
              <div class="pi_content plan_info_amount">
                {{ paymentAlert.pendingInvoice.plan_duration }} Month
              </div>
            </div>
            <v-divider></v-divider>
            <template
              v-for="(payment, key) in Object.keys(
                paymentAlert.pendingInvoice.payment_heads
              )"
            >
            <template>
              <small :key="key + 6000"
                ><strong>({{ payment }})</strong></small
              >
              <div
                v-for="head in paymentAlert.pendingInvoice.payment_heads[payment]"
                class="plan_info"
                :key="head.id"
                style="margin: 5px 0"
              >
                <div class="pi_content plan_info_title" style="font-size: 14px">
                  {{ head.display_title }}
                </div>
                <div class="pi_content plan_info_amount" style="font-size: 14px">
                  {{ head.amount.currency() }}
                </div>
              </div>
              <v-divider :key="key + 100"></v-divider>
            </template>
            </template>

            <div class="plan_info">
              <div class="pi_content plan_info_title">Total Amount</div>
              <div class="pi_content plan_info_amount">
                {{ paymentAlert.pendingInvoice.amount.currency() }}
              </div>
            </div>

            <br />
            <v-btn
              v-if="!paymentAlert.pendingInvoice.is_expired"
              class="mb-3"
              block
              outlined
              large
              color="success"
              small
              @click="
                remindLaterAlert(paymentAlert.pendingInvoice),
                  $router.push({ name: 'settings', query: { tab: 'payments' } })
              "
            >
              Pay Now
            </v-btn>

            <v-btn
              v-if="!paymentAlert.pendingInvoice.is_expired"
              color="warning"
              block
              large
              :disabled="false"
              outlined
              @click="remindLaterAlert(paymentAlert.pendingInvoice)"
            >
              <span v-if="paymentAlert.pendingInvoice.action_type === 'error'">
                CLOSE ALERT!! I DON'T CARE
              </span>
              <span v-else> REMIND ME AFTER AFTER 3 DAYS </span>
            </v-btn>
            <!-- DIGITAL PAYMENT OPTION -->
            <v-btn
              v-if="paymentAlert.pendingInvoice.is_expired ||($auth.getRole() === 'administrator' || $auth.getRole() === 'accountant')"
              class="mb-3"
              block
              outlined
              large
              color="#41a124"
              small
              @click="makePayments()"
            >
              Pay By Esewa
            </v-btn>
            <v-btn
              id="payment-button"
              v-if="paymentAlert.pendingInvoice.is_expired ||($auth.getRole() === 'administrator' || $auth.getRole() === 'accountant')"
              class="mb-3"
              block
              outlined
              large
              color="#5d2e8e"
              small
              @click="openKhaltiModal()"
            >
              Pay By Khalti
            </v-btn>
            <!-- <v-btn
              v-if="paymentAlert.pendingInvoice.is_expired &&($auth.getRole() === 'administrator' || $auth.getRole() === 'accountant')"
              class="mb-3"
              block
              outlined
              large
              color="#cf0a10"
              small
              @click="fonePay()"
            >
              FonePay
            </v-btn> -->
            <!-- Voucher Upload -->
            <v-btn
              id="upload-voucher-button"
              v-if="paymentAlert.pendingInvoice.is_expired ||($auth.getRole() === 'administrator' || $auth.getRole() === 'accountant')"
              class="mb-3"
              block
              outlined
              large
              color="info"
              small
              @click="
                $refs.image.click(),
                  (documentType = 'voucher'),
                  (invoice_id = paymentAlert.pendingInvoice.last_id)
              "
            >
              Upload Vouchers
            </v-btn>
            <!-- <edit-button
              class="mb-3"
              block
              outlined
              large
              color="success"
                edit-text="Select File"
                @agree="
                  $refs.image.click(),
                    (documentType = 'voucher'),
                    (invoice_id = paymentAlert.pendingInvoice.last_id)
                "
                permission="staff-create"
                label="Upload Voucher"
                icon="article"
              >
            </edit-button> -->
            <input
              type="file"
              style="display: none"
              ref="image"
              multiple
              accept="image/*"
              @change="onFilePicked"
            />
            <!-- Voucher Upload end -->
            <!-- END FOR DIGITAL PAYMENT -->
            <br />
            <small v-if="($auth.getRole() === 'administrator' || $auth.getRole() === 'accountant')"><strong>NOTE:</strong> Alert is only shown to administrator and
              account members.</small
            >
            <br />
            <p v-if="($auth.getRole() === 'administrator' || $auth.getRole() === 'accountant')">
              <strong
                ><code style="color: #333; font-weight: bold">
                  Mega Bank Nepal Ltd., Maharajgunj</code
                >
                &nbsp;
                <code style="color: #333; font-weight: bold">
                  Sahakarya Tech Pvt. Ltd.</code
                >&nbsp;
                <code style="color: #333; font-weight: bold">0200010139672</code
                >&nbsp; or you can
                <a href="" @click.prevent="scantoPayAlert = true"
                  ><u>scan to pay</u></a
                >
                <!-- ======================ESEWA PAYMENT=================== -->
                <form
                  action="https://uat.esewa.com.np/epay/main"
                  method="POST"
                  id="makePayment"
                  name="make-payment"
                >
                  <input
                    :value="paymentAlert.totalPaymentAmount"
                    name="tAmt"
                    type="hidden"
                  />
                  <!-- <input
                    value="5"
                    name="amt"
                    type="hidden"
                  /> -->
                  <input
                    :value="paymentAlert.pendingInvoice.total_amount_no_vat"
                    name="amt"
                    type="hidden"
                  />
                  <input :value="total_tax_amount" name="txAmt" type="hidden" />
                  <!-- <input value="0" name="txAmt" type="hidden" /> -->
                  <input value="0" name="psc" type="hidden" />
                  <input value="0" name="pdc" type="hidden" />
                  <!-- <input
                    value="ee21c3ca1-696b-4cc5-a6bee-1w40d929e164r1"
                    name="pid"
                    type="hidden"
                  /> -->
                  <input
                    :value="paymentAlert.pendingInvoice.last_id"
                    name="pid"
                    type="hidden"
                  />
                  <!-- <input value="EPAYTEST" name="scd" type="hidden" /> -->
                  <input  name="scd" type="hidden"  id="scd"/>
                  <input
                    :value="systemDomain + '?payment_success=true&q=su&type=1'"
                    type="hidden"
                    name="su"
                  />
                  <input
                    :value="systemDomain + '?payment_success=false&q=fu&type=1'"
                    type="hidden"
                    name="fu"
                  />
                  <!-- <input value="Esewa" type="submit">  -->
                </form>
                <!-- ================ Close ESEWA PAYMENT FORM ================-->
                <!-- ========================== FonePay ===================== -->
                <form
                  action="https://dev-clientapi.fonepay.com/api/merchantRequest?"
                  method="GET"
                  id="fonePay"
                >
                  <input type="hidden" name="PID" value="NBQM" />
                  <input type="hidden" name="MD" value="P" />
                  <input type="hidden" name="AMT" value="10" />
                  <input type="hidden" name="CRN" value="NPR" />
                  <input type="hidden" name="DT" value="11/24/2021" />
                  <input type="hidden" name="R1" value="Test" />
                  <input type="hidden" name="R2" value="Test" />
                  <input type="hidden" name="DV" :value="hmac_value" />
                  <input
                    type="hidden"
                    name="RU"
                    :value="systemDomain + '/payment_success?type=3'"
                  />
                  <input type="hidden" name="PRN" value="Schoolss" />
                </form>
                <!-- ========================== close FonePay =============== -->
              </strong>
            </p>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text>
            <br>
            <!-- {{$auth.getRole('role')}} {{has_expired}} -->
          <h1>System has been blocked due to pending dues.</h1>
        </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="scantoPayAlert" persistent max-width="700">
        <v-card class="primary--border">
          <v-card-title :class="'title pa-3 white--text ' + 'success'">
            Fonepay
            <v-spacer></v-spacer>
            <v-icon
              style="cursor: pointer"
              @click="scantoPayAlert = false"
              class="mr-2 white--text"
              >close</v-icon
            >
          </v-card-title>
          <v-card-text class="ma-0">
            <br />
            <img
              style="width: 100%"
              src="https://eacademy.sgp1.digitaloceanspaces.com/scan_to_pay.jpeg"
              alt="scan_to_pay"
            />

            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-dialog>
    <!-- </div> -->
  </div>
</template>
<script>
import cookie from "js-cookie";
import jsSHA from "jssha";
import { mapActions, mapState } from "vuex";
import KhaltiCheckout from "khalti-checkout-web";
import config from "@/library/Config.js";
export default {
  data: () => ({
    scantoPayAlert: false,
    khalti: "",
    esewa_key: process.env.VUE_APP_ESEWA_KEY, //EPAYTEST
    has_expired:false
  }),
  mounted() {
    this.getPendingPayment();
    setTimeout(() => {
      this.has_expired = this.paymentAlert.pendingInvoice.is_expired 
    }, 2000);

  },
  computed: {
    ...mapState(["paymentAlert"]),
    total_tax_amount: function () {
      return (
        this.paymentAlert.totalPaymentAmount -
        this.paymentAlert.pendingInvoice.total_amount_no_vat
      );
    },
    systemDomain() {
      return config.SYSTEM_HOST;
    },
    hmac_value() {
      const shaObj = new jsSHA("SHA-512", "TEXT", {
        encoding: "UTF8",
        hmacKey: { value: "a7e3512f5032480a83137793cb2021dc", format: "TEXT" },
      });
      let return_url = this.systemDomain + "/payment_success?type=3";
      let data =
        "NBQM" +
        "," +
        "P" +
        "," +
        "Schoolss" +
        "," +
        "10" +
        "," +
        "NPR" +
        "," +
        "11/24/2021" +
        "," +
        "Test" +
        "," +
        "Test" +
        "," +
        return_url;
      shaObj.update(data);
      const hmac = shaObj.getHash("HEX");
      return hmac;
    },
  },
  methods: {
    ...mapActions(["getPendingPayment", "updateReminder"]),

    onFilePicked(e) {
      const files = e.target.files;
      const formData = new FormData();
      formData.append("document_type", this.documentType);
      formData.append("invoice_id", this.invoice_id);
      files.forEach((file) => {
        formData.append("documents[]", file);
      });
      this.$rest
        .post("/api/payment/document", formData)
        .then((res) => {
          if (res.status == 200) {
            this.$events.fire("notification", {
              message: "Payment is under review",
              status: "success",
            });
          }
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        });
    },
    fonePay() {
      $("#fonePay").submit();
    },
    makePayments() {
      document.getElementById("makePayment").action = process.env.VUE_APP_ESEWA_URL;
      document.getElementById("scd").setAttribute("value", process.env.VUE_APP_ESEWA_KEY);

      $("#makePayment").submit();
    },
    remindLaterAlert(payment) {
      this.updateReminder({
        alert_type: payment.alert_type,
        id: payment.id,
      })
        .then((res) => {
          cookie.set("_al_ch_for", res.data.remind_later_flag, { expires: 8 });
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: "Something went wrong please contact support",
            status: "error",
          });
        });
    },
    verifyKhaltiPayment(data) {
      window.location.href =
        "/payment_success?type=2&" +
        "token=" +
        data.token +
        "&amount=" +
        data.amount +
        "&product_identity=" +
        data.product_identity;
    },
    async openKhaltiModal() {
      await this.khaltiPayment();
      let total_amount = parseInt(this.paymentAlert.totalPaymentAmount * 100);
      // let total_amount = 1000;
      this.khalti.show({ amount: total_amount });
    },

    khaltiPayment() {
      let that = this;
      var config = {
        // publicKey:"test_public_key_44ae3a94446b4f1aa7022635f4257f36",
        publicKey: process.env.VUE_APP_KHALTI_PUBLIC_KEY,
        // productIdentity: that.paymentAlert.pendingInvoice.last_id,
        productIdentity: that.paymentAlert.pendingInvoice.id,
        productName: "EAcademy Subscription Payment",
        productUrl: that.systemDomain,
        paymentPreference: [
          "KHALTI",
          // "EBANKING",
          // "MOBILE_BANKING",
          // "CONNECT_IPS",
          // "SCT",
        ],
        eventHandler: {
          onSuccess(payload) {
            if (payload.idx) {
              window.location.href =
                that.systemDomain +
                "?payment_success=true&type=2&" +
                "token=" +
                payload.token +
                "&amount=" +
                payload.amount +
                "&product_identity=" +
                payload.product_identity +
                "&widget_id=" +
                payload.widget_id+
                "&success_payment=true";
            }
            // hit merchant api for initiating verfication
          },
          onError(error) {
            // console.log(error);
          },
          onClose() {
            // console.log("widget is closing");
          },
        },
      };

      // var checkout = new KhaltiCheckout(config);
      that.khalti = new KhaltiCheckout(config);
      // var btn = document.getElementById("payment-button");
      // btn.onclick = function () {
      //     // minimum transaction amount must be 10, i.e 1000 in paisa.
      //     checkout.show({amount: total_amount});
      //     // checkout.show({amount: 1000});
      // }
    },
  },
};
</script>
<style lang="scss">
.plan_info {
  display: flex;
  margin: 20px 0;
  .pi_content {
    flex: 1;
    font-size: 17px;
    font-weight: 500;
  }
  .plan_info_amount {
    text-align: right;
    color: #666;
    font-size: 18px;
    font-weight: bold;
  }
  .plan_info_title {
    color: #939a9f;
  }
  .primary--border#payment-button {
    color: #5d2e8e !important;
  }
}
</style>
